// This class is a modified card class from Bootstrap
.card {
    min-height: 20rem;
    border: none;
    box-shadow: 5px 5px 0 5px rgba($qudos-grey, 0.1);

    &-body {
        margin-top: 1rem;
    }

    &-b {
        padding: 2rem 1.5rem;
        border-radius: 5px;
        background-color: white;
        max-height: 24rem;
        min-height: 24rem;
        box-shadow: $box-shadow-default;
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        cursor: pointer;

        &:hover {
            transform: translateY(-.5rem);
            box-shadow: $box-shadow-hovered;
        }

        &:active {
            transform: translateY(0);
            box-shadow: $box-shadow-actived;
        }
    }

    &-c {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 2rem 0;
        background-color: $qudos-lighter-grey;
        height: 20rem;
        max-width: 25rem;
        min-width: 20rem;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 5px;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        p {
            max-width: 100%;
            color: rgba(black, .65);
        }

        &__title {
            font-size: 2.5rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        &__code {
            font-size: 2rem;
            font-weight: 700;
            font-family: inherit;
        }
    }
}

@media (max-width: 767px) {
    .card {
        &-text {
            .text-muted {
                font-size: 1rem;
            }
        }
    }
}

@media (max-width: 575px) {
    .card {
        width: 40%;
        min-width: 20rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 425px) {
    .card {
        min-height: 25rem;

        &-title {
            font-size: 1.75rem;
        }

        &-text {
            .text-muted {
                font-size: 1.25rem;
            }
        }
    }
}