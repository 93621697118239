.faq {
    [data-toggle~="collapse"] {
        cursor: pointer;
    }

    .qudos-blue {
        color: #228ec8;
    }

    a.card-title {
        color: #212529 !important;
        font-size: 1.25rem !important;
        font-weight: 600;
    }

    a.card-title:hover {
        text-decoration: none !important;
    }

    .card-header:hover {
        cursor: pointer;
    }

    .accordion .card-header {
        &:after {
            font-family: 'Font Awesome\ 5 Free';
            font-size: 1.75rem;
            font-weight: 600;
            content: "\f077";
            float: right;
            color: #e6e7e8
        }

        &.collapsed:after {
            /* symbol for "collapsed" panels */
            content: "\f078";
        }
    }

    p.subheader__prime {
        font-weight: 400;
    }

    .subheader__prime {
        line-height: 3rem;
    }


    .card {
        border: 0;
        margin-bottom: .5rem;
        min-height: 0;
        box-shadow: 1px 5px 10px 0px rgba(180, 180, 180, .1);
    }

    .card-header {
        // font-size: 2.5rem;
        background: #fff;
        border: none;
        padding: 15px;
        padding-left: 20px;
    }



    .section-core {
        &--faq {
            display: flex;
            align-items: center;
            height: 18rem;
            position: relative;

            .card-body {
                font-size: 1.2rem;
                line-height: 2.5rem;
                font-weight: 400;
                color: #676767;
                text-align: inherit;
                margin-top: 0rem;

                p {
                    padding-left: 1rem;
                }

                li {
                    text-indent: 1.3rem;
                }
            }
        }

        &--outro {
            background-image: url(../../core__assets/img/core__faq/banner.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            background-attachment: scroll;
            padding: 5rem 0;
        }

    }

    .core {
        &__header {
            &--h1 {
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 3rem;
                position: relative;

                &::after {
                    content: '';
                    // background: none;
                }
            }
        }
    }
}



@media (max-width: 1440px) {
    .faq {
        .subheader__prime {
            width: 100%;
            font-size: 2rem;
        }
    }
}

@media (max-width: 1120px) {
    .faq {
        .why-qudos__section--2 {
            height: 65vh;
        }

        .carousel-inner {
            min-height: 40vh;
        }
    }
}

@media (max-width: 1024px) {
    .faq {
        .subheader__prime {
            width: 100%;
            font-size: 1.5rem;
        }
    }
}

@media (max-width: 768px) {
    .faq {
        .section [class^=col-] {
            padding: 4rem 0;
        }
    }
}

@media (max-width: 480px) {
    .faq {
        .card {
            width: 100%;
        }

        .card-header {
            font-size: 1.8rem;
        }

        .card-body {
            font-size: 1rem;
        }

        .carousel-inner {
            min-height: 40vh;
        }

        .card-body li {
            text-indent: 0rem;
            padding-left: 1rem;
        }

        .accordion .card-header {
            &:after {
                position: absolute;
                right: 0.25rem;
            }
        }

        .section-core {
            
            &--outro {
                background-image: url(../../core__assets/img/core__faq/banner.png);
                background-position: center right;

            }
        }

    }
}