// Copied Tooltip
.copy-notification {
    color: white;
    background-color: rgba(black, 0.8);
    padding: 1rem;
    border-radius: 2.5rem;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 10rem;
    margin-top: -3rem;
    margin-left: -8.5rem;
    display: none;
    text-align: center;
    opacity: 0;
}

.section-core {
    &--new-codes {
        .col-12 {
            padding-top: 3rem;
            padding-bottom: 5rem;

            small {
                margin-top: 1.5rem;
                display: block;
                max-width: 55rem;
                margin-left: auto;
                margin-right: auto;
                font-size: .65rem;
            }
        }

        .clip-btn {
            position: relative;

            &::before {
                content: 'Copy to clipboard';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 10rem;
                height: 100%;
            }

            &.action {
                &::before {
                    content: 'Copied!';
                }
            }
        }

        .table {
            // min-height: 25rem;
            max-width: 55rem;
            margin-left: auto;
            margin-right: auto;

            thead {
                th {
                    font-size: .85rem;
                    font-weight: 500;
                }

                th:first-child {
                    padding-left: 3rem;
                    padding-top: 1rem;
                }
            }

            tbody {
                background-color: rgba($qudos-lighter-grey, .5);

                td {
                    padding: 1rem;
                }

                td:nth-child(2) {
                    width: 34%;
                }

                td:nth-child(3) {
                    font-weight: 700;
                    width: 22%;
                }

                th {
                    width: 16%;
                    font-weight: 700;
                    padding-left: 3rem;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
            }
        }
    }

    &--new-codes+&--code-mobile {

        .col-sm-12 {
            padding-top: 3rem;
        }
    }

    &--new-codes~&--confirm-book {
        margin-top: 7rem;
        background-color: rgba($qudos-lighter-grey, .25);

        .btn--primary {
            max-width: 15rem;
        }

        p {
            margin-bottom: 2rem;
        }

        a {
            text-decoration: none;
        }
    }
}

@media (max-width: 767px) {
    .section-core {
        &--new-codes+&--code-mobile {
            .col-md-4 {
                padding-top: 8rem;
            }
        }
    }
}

@media (max-width: 575px) {
    .section-core {
        &--new-codes {
            .card-c {
                display: flex !important;
            }

            .clip-btn {
                width: 100%;
                height: 5rem;
                color: black;
                font-size: 1.5rem;
                margin-top: auto;

                &::before {
                    top: 35%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                }

                &:hover {
                    color: $qudos-blue;
                }
            }

            .col-12 {
                small {
                    font-size: 1.2rem;
                    max-width: 25rem;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .section-core {
        &--new-codes~&--confirm-book {
            .col-sm-12 {
                p {
                    max-width: 85%;
                }

                a {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}