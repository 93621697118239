/*==============================
  Header
==============================*/

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: 0.3s;
    // background-color: white;

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 4.8rem;
        position: relative;
    }

    &__logo {
        position: relative;
        height: 4rem;
        display: block;
        cursor: pointer;

        object {
            width: 10rem;
            transition: 0.3s;
        }

        object:last-child {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }

    &__nav {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 0;
        background-color: white;
        right: 0;
        transform: translate3d(700px, 0, 0);
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;

        a {
            font-size: $default-anchor;
            color: #0f1423;
            margin-bottom: 2rem;
            font-family: inherit;
        }

        a:last-child {
            margin-bottom: 0;
        }

        &--active {
            width: 100%;
            transform: translate3d(0, 0, 0);
        }
    }

    &__social {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        border-top: 2px solid black;
        padding: 2rem 1rem 0;
        margin-top: 2rem;

        a {
            margin: 0 5px;
            font-size: 1rem;
        }
    }

    &__btn {
        max-width: 10rem;
        color: rgba(white, .95);
        font-size: $default-anchor;
        font-weight: 600;
        border-radius: 3px;
        text-align: center;
        background-color: $qudos-blue;
        // box-shadow: 0 16px 10px -10px rgba($qudos-blue, 0.6);
        background-image: none;
        margin-right: 4rem;
        padding: .75rem 1.25rem;
        transition: all .2s;
        position: relative;

        &:hover {
            color: white;
        }

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all .2s;
        }

        &:hover::after {
            outline: rgba($qudos-blue, 1);
            box-shadow: 0 0 2rem 5px rgba($qudos-blue, .2);
        }
    }

    &__menu {
        position: absolute;
        width: 2.9rem;
        height: 1.8rem;
        display: block;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 2;

        span {
            display: block;
            position: absolute;
            width: 100%;
            height: .2rem;
            left: 0;
            background-color: black;
            transition: 0.3s;
            opacity: 1;
        }

        span:first-child {
            top: 0;
        }

        span:nth-child(2) {
            top: 50%;
            margin-top: -1px;
        }

        span:last-child {
            top: 100%;
            margin-top: -2px;
        }

        &--active {
            span {
                background-color: #0f1423;
            }

            span:first-child {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                transform: rotate(-45deg);
                top: 50%;
                margin-top: -2px;
            }

            span:nth-child(2) {
                opacity: 0;
            }

            span:last-child {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 50%;
            }
        }
    }

    &__inverted {
        .header__menu {
            span {
                background-color: white;
            }
            &--active {
                span {
                    background-color: black;
                }
            }
        }
        @media (min-width: 768px) {
            .header__nav {
                a {
                    margin: 0 0 0 3rem;
                    color: white;
                    font-weight: 500;
                }
            }
        }
    }

    &--active,
    &--white {
        background-color: white;
        background-image: linear-gradient(90deg, #171f2d 0%, #03030a 100%);
        background-image: none;
        -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    }
}

.header--active .header__nav a,
.header--white .header__nav a {
    color: black;
}

.header--active .header__menu span,
.header--white .header__menu span {
    background-color: black;
}

.header--active .header__content:before,
.header--white .header__content:before {
    display: none;
}

.header--active .header__logo object:first-child,
.header--white .header__logo object:first-child {
    opacity: 0;
}

.header--active .header__logo object:last-child,
.header--white .header__logo object:last-child {
    opacity: 1;
}

@media (min-width: 768px) {
    .header {
        .container {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
        }

        &__content:before {
            display: none;
        }

        &__menu {
            display: none;
        }

        &__btn {
            margin: 0;
        }

        &__logo {
            height: 4rem;
            margin-left: 0;

            object {
                width: 10rem;
            }
        }

        &__nav {
            position: absolute;
            flex-direction: row;
            align-items: center;
            width: auto;
            transform: translate3d(0, 0, 0);
            padding: 0;
            right: 10rem;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;

            &--active {
                right: 0;

                a {
                    margin: 0;
                }
            }

            a {
                margin: 0 0 0 3rem;
                color: black;
                font-weight: 500;

                &.desk {
                    display: none;
                }
            }
        }

        &__social {
            display: none;
        }
    }

    .header--active .header__nav a,
    .header--white .header__nav a {
        color: black;
    }

    .header--active .header__nav a:hover,
    .header--white .header__nav a:hover {
        color: #0f1423;
    }

    .header--white .header__nav {
        right: 0;
    }
}


@media (max-width: 991px) {
    .header {
        &__nav {
            a {
                margin: 0 1rem;
            }

            &--active {
                right: 0;

                &>a {
                    &:not(:last-child) {
                        margin: 0 0 1.5rem 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .header {
        &__btn {
            font-size: 1.2rem;
            min-width: 11rem;
        }

        &__nav {
            a {
                font-size: 1.2rem;

                i {
                    font-size: 1.5rem;
                }
            }
        }
    }
}