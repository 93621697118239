.core {
    &__header {
        &--h1 {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 3rem;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 8.6rem;
                height: 3px;
                bottom: -1.5rem;
                left: 0;
                background: $blues-gradient--linear;
            }
        }

        &--h2 {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 1.75rem;
            position: relative;
        }

        &--h2-5 {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 3rem;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 8.6rem;
                height: 3px;
                bottom: -1.5rem;
                left: 0;
                background: $blues-gradient--linear;
            }
        }

        &--h2-75 {
            font-size: 1.55rem;
            font-weight: 700;
        }

        &--h3 {
            font-size: 1.95rem;
            font-weight: 700;
            margin-bottom: 1.75rem;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 8.6rem;
                height: 3px;
                bottom: -1.5rem;
                left: 50%;
                transform: translateX(-50%);
                background: $blues-gradient--linear;
            }
        }

        &-special {
            &--1 {
                display: inline-block;
                font-size: 3rem;
                line-height: 4.2rem;
                font-weight: 700;
                margin-bottom: 2rem;
            }

            &--2 {
                display: inline-block;
                font-size: 3rem;
                line-height: 4.2rem;
                font-weight: 700;
                margin-bottom: 2rem;

                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 8.6rem;
                    height: 3px;
                    bottom: -1.5rem;
                    left: 50%;
                    transform: translateX(-50%);
                    background: $blues-gradient--linear;
                }
            }
        }
    }

    &__parag {
        font-size: $default-anchor;
        font-weight: 400;
        color: rgba($qudos-darkgrey, .75);
        max-width: 80%;
        min-width: 75%;
        line-height: calc(1.5rem - .15vw);
        margin-bottom: 2rem;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}

@media (max-width: 991px) {
    .core {
        &__header--h1 {
            font-size: 1.8rem;
        }

        &__parag {
            font-size: .8rem;
        }
    }
}

@media (max-width: 767px) {
    .core {
        &__parag {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

@media (max-width: 480px) {
    .core {
        &__header {
            &-special {
                &--2 {
                    font-size: 2.8rem;
                }
            }
        }
    }
}