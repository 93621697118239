/*==============================
  Displays
==============================*/
@media (min-width: 576px) {
  .d-mw--575 {
    display: block !important;
  }

  .h-mw--575 {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .d-mw--575 {
    display: none !important;
  }

  .h-mw--575 {
    display: block !important;
  }
}

@media (min-width: 473px) {
  .d-mw--472 {
    display: block !important;
  }

  .h-mw--472 {
    display: none !important;
  }
}

@media (max-width: 472px) {
  .d-mw--472 {
    display: none !important;
  }

  .h-mw--472 {
    display: block !important;
  }
}


/*==============================
  Borders
==============================*/

.border-solid-right {
  border-right: 1px solid rgba(lightgrey, .5);
}

.border-solid-bottom {
  border-bottom: 1px solid rgba(lightgrey, .5);
}

/*==============================
  Text colouring
==============================*/

.qudos__blue-text {
  color: $qudos-blue !important;
}

.qudos__navyblue-text {
  color: $qudos-navyblue !important;
}

.black-text {
  color: black !important;
}

.white-text {
  color: white !important;
}

/*==============================
  Font weights
==============================*/

.fw-700 {
  font-weight: 700 !important;
}

/*==============================
  Servicemarks / Trademarks
==============================*/

.qudos__service-mark {
  font-size: .7rem;
  margin-bottom: auto;
  line-height: 5.5rem;
  vertical-align: top;
}

/*==============================
  Clearfix for floats
==============================*/

.clear {
  display: block;
  content: "";
  clear: both;
}

/*==============================
  Width sizing
==============================*/
.w-30 {
  width: 30% !important;
}

/*==============================
  Shadow
==============================*/
.no-shadow {
  filter: none !important;
  box-shadow: none !important;
}

/*==============================
  International flag icon
==============================*/

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2 / 1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.4.0/img/flags@2x.png");
  }
}