.section-core {
    &--corporate {
        display: flex;
        align-items: center;
        height: 48rem;
        position: relative;

        p {
            max-width: 100%;
        }

        // images
        #platform-5 {
            top: 6rem;
        }

        #platform-6 {
            top: -5.5rem;
            left: 5.5rem;
            width: 80%;
        }
    }

    &--live-map {
        background-color: $qudos-lighter-grey;

        .col-md-7 {
            padding: 3rem;

            .core__parag {
                max-width: 100%;
            }
        }
    }

    &--choose-fave {
        background-color: $qudos-navyblue;
        background-image: url(../img/core__corporate/choose-fave-bg.jpg);
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: 100% center;
    }

    &--confirm-book {
        a {
            text-decoration: underline;
            color: $qudos-blue;
        }

        img {
            width: 55%;
        }
    }
}

@media (max-width: 1770px) and (min-width: 1200px) {
    .section-core {
        &--corporate {
            #platform-6 {
                top: -7.5rem;
                left: 6.5rem;
            }
        }
    }
}

@media (max-width: 1475px) {
    .section-core {
        &--choose-fave {
            background-size: contain;
        }
    }
}

@media (max-width: 1199px) {
    .section-core {
        &--choose-fave {
            background-position-x: 40rem;
        }
    }
}

@media (max-width: 1080px) {
    .section-core {
        &--choose-fave {
            background-position-x: 35rem;
        }
    }
}

@media (max-width: 991px) {
    .section-core {
        &--corporate {
            #platform-6 {
                top: -2.7rem;
                left: 4.5rem;
            }
        }

        &--choose-fave {
            background-image: url(../img/core__corporate/choose-fave-bg@991.jpg);
            background-position-x: right;
        }
    }
}

@media (max-width: 935px) {
    .section-core {
        &--choose-fave {
            background-position-x: 35rem;
        }
    }
}

@media (max-width: 925px) and (min-width: 768px) {
    .section-core {
        &--corporate {
            #platform-6 {
                top: -3.7rem;
                left: 4rem;
            }
        }
    }
}

@media (max-width: 825px) {
    .section-core {
        &--choose-fave {
            background-position-x: 30rem;
        }
    }
}

@media (max-width: 767px) {
    .section-core {
        &--corporate {
            overflow: hidden;
            margin-top: 5rem;

            #platform-6 {
                top: -4.8rem;
                left: 5.5rem;
            }

            .core__parag {
                max-width: 60%;
                min-width: 50%;
            }

            .col-sm-12:first-child {
                &>span {
                    left: 24rem;
                }
            }
        }

        &--portal {
            .d-block {
                display: inline !important;
            }

            .col-12:first-child {
                padding-top: 0;
            }
        }

        &--live-map {
            [class^=col-] {
                padding: 5rem 0;
            }

            .col-sm-12:last-child {
                padding-top: 0;
            }
        }

        &--choose-fave {
            background-image: url(../img/core__corporate/choose-fave-bg@767.jpg);
            background-position: center bottom;

            .col-sm-12:first-child {
                padding-top: 5rem;
            }
        }

        &--confirm-book {
            .col-sm-12:first-child {
                order: 2;
                padding-top: 0;
                justify-content: flex-start !important;
            }

            .col-sm-12:last-child {
                padding-bottom: 5rem;
            }
        }
    }
}

@media (max-width: 575px) {
    .section-core {
        &--live-map {
            [class^=col-] {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        &--choose-fave {
            [class^=col-] {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .col-sm-12:first-child {
                padding-bottom: 3rem;
            }
        }

        &--confirm-book {
            [class^=col-] {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
}

@media (max-width: 425px) {
    .section-core {
        &--corporate {
            overflow: visible;

            #platform-6 {
                top: -3.10rem;
                left: 4.5rem;
            }

            .col-sm-12:first-child {
                &>span {
                    top: -9rem;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .col-sm-12:last-child {
                text-align: center;

                &>span {
                    margin-top: 2rem;
                    position: relative;
                    top: 14rem;
                }

                h1 {
                    text-align: center;

                    &::after {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                p {
                    max-width: 100%;
                    min-width: 100%;
                    text-align: center;
                }
            }
        }

        &--live-map {

            h2,
            p {
                text-align: center;
            }
        }

        &--portal {
            padding-top: 10rem;
        }

        &--choose-fave {

            h2,
            p {
                text-align: center;
            }

            p {
                margin-left: auto;
                margin-right: auto;
                max-width: 90%;
            }

            .col-sm-12:first-child {
                padding-bottom: 0;
            }
        }

        &--confirm-book {
            .col-sm-12:first-child {
                justify-content: center !important;
            }
            .col-sm-12:last-child {
                padding-top: 5rem;
                padding-bottom: 2rem;

                h2,
                p {
                    text-align: center;
                }
                p {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 80%;
                }
            }
        }
    }
}

@media (max-width: 396px) {
    .section-core {
        &--corporate {
            #platform-6 {
                top: -2.3rem;
                left: 4.6rem;
            }
        }
    }
}

@media (max-width: 380px) {
    .section-core {
        &--corporate {
            #platform-6 {
                top: -2.3rem;
                left: 3.6rem;
            }
        }
    }
}

@media (max-width: 380px) and (min-width: 326px) {
    .section-core {
        &--corporate {
            .col-sm-12:first-child {
                & >span {
                    top: -7rem;
                }
            }
        }
    }
}

@media (max-width: 350px) {
    .section-core {
        &--corporate {
            #platform-6 {
                top: -1.3rem;
                left: 3.9rem;
            }
        }
    }
}

@media (max-width: 325px) {
    .section-core {
        &--corporate {
            #platform-6 {
                top: -1.3rem;
                left: 2.9rem;
            }
            .col-sm-12:first-child {
                & >span {
                    top: -5rem;
                }
            }
        }
    }
}