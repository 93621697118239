.qudos-list {
    li {
        position: relative;
    }

    li:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    li:first-child {
        &::after {
            content: '1';
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: -3rem;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 700;
            vertical-align: middle;
            line-height: 2.2rem;
            color: white;
            background-color: $qudos-blue;
        }
    }

    li:nth-child(2) {
        &::after {
            content: '2';
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: -3rem;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 700;
            vertical-align: middle;
            line-height: 2.2rem;
            color: white;
            background-color: $qudos-blue;
        }
    }

    li:nth-child(3) {
        &::after {
            content: '3';
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: -3rem;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 700;
            vertical-align: middle;
            line-height: 2.2rem;
            color: white;
            background-color: $qudos-blue;
        }
    }
}

@media (max-width: 767px) {
    .qudos-list {
        li {
            font-size: 1.5rem;
        }
    }
}