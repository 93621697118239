/*==============================
  Values
==============================*/

.values {
  position: relative;
  z-index: 3;
  padding: 6.5rem 0;
  background-color: white;

  &__img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 40px;
    width: 30rem;
  }

  &__box {
    position: relative;
    display: block;
    margin: 5.5rem auto 0;
    width: 100%;
    max-width: 36rem;

    &-square {
      display: inline-block;
      position: absolute;
      top: -.5rem;
      left: -.5rem;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      background-color: $qudos-lighter-grey;
      z-index: -1;
    }

    h3 {
      display: block;
      font-size: 1.65rem;
      color: black;
      font-family: inherit;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    img {
      margin-bottom: 1rem;
      width: 40%;
    }

    p {
      display: block;
      margin: 0;
    }
  }
}

@media (min-width: 768px) {
  .values__box {
    padding-left: 0;
    max-width: 27rem;
    margin: 5rem auto 0;

    object {
      top: 0;
      left: -40px;
    }
  }
}

@media (min-width: 992px) {
  .values {
    padding: 8rem 0;
  }
}

@media (min-width: 1200px) {
  .values {
    padding: 5rem 0 10rem;
  }
}

@media (max-width: 480px) {
  .values {
    &__box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      p {
        text-align: center;
      }
    }
  }
}