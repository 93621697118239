.dropdown {
    &-item {
        padding: 0;
        &:hover {
            background-color: $qudos-blue;
        }
        .row {
            padding: .25rem 1.2rem;
        }
    }

    &-menu {
        padding: 0;
        max-width: 10rem;
        min-width: 10rem;
        background-color: transparent;
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: $qudos-blue;
            z-index: -1;
            opacity: .85;
        }
    }

    &-text {
        font-size: $default-anchor;
    }
}

@media (max-width: 480px) {
    .dropdown {
        &-item {
            padding: 0;
            .row {
                padding: .25rem 2rem;
            }
        }
    }
}