.section-core {
    &--qudoslist {
        display: flex;
        align-items: center;
        height: 38rem;
        position: relative;
        background-image: url(../img/core__qudoslist/qudoslist-hero-bg.svg);
        background-repeat: no-repeat;
        background-size: cover;

        .col-md-5 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 70%;
            }
        }

        .col-md-6 {
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                width: 70%;
                margin-bottom: 1rem;
            }
        }
    }

    &--qudoslist+&--what-can {
        .col-12 {
            padding-top: 8rem;
        }
    }

    &--qudoslist~&--factors {
        .row:nth-child(3) {
            &>.col-md-7 {
                display: flex;
                flex-flow: column;
                justify-content: center;
                padding-left: 4rem;
                padding-top: 0;

                p {
                    max-width: 90%;
                }
            }

            &>.col-sm-12 {
                padding-top: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .section-core {
        &--qudoslist {
            .col-md-5 {
                img {
                    padding-top: 4rem;
                    width: 45%;
                }
            }

            .col-md-6 {
                img {
                    width: 60%;
                    padding-top: 4rem;
                    margin-left: auto;
                    margin-right: auto;
                }

                p {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &--qudoslist~&--factors {
            .row:nth-child(3) {
                &>.col-md-7 {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    padding-left: 0;
                    padding-top: 3rem;
    
                    p {
                        max-width: 90%;
                    }
                }
                &>.col-sm-12 {
                    padding-top: 5rem;
                }
            }
        }
    }
}