@keyframes float {
	0% {
		filter: drop-shadow(0 5px 1.5rem rgba(0, 0, 0, 0.2));
		-webkit-filter: drop-shadow(0 5px 1.5rem rgba(0, 0, 0, 0.2));
		transform: translatey(0);
	}

	50% {
		filter: drop-shadow(0 2.5rem 1.5rem rgba(0, 0, 0, 0.1));
		-webkit-filter: drop-shadow(0 2.5rem 1.5rem rgba(0, 0, 0, 0.1));
		transform: translatey(-2rem);
	}

	100% {
		filter: drop-shadow(0 5px 1.5rem rgba(0, 0, 0, 0.2));
		-webkit-filter: drop-shadow(0 5px 1.5rem rgba(0, 0, 0, 0.2));
		transform: translatey(0);
	}
}

@keyframes swing {
	0% {
		transform: rotate(3deg);
	}

	50% {
		transform: rotate(-6deg);
	}

	100% {
		transform: rotate(3deg);
	}
}

@keyframes sway {
	0% {
		transform: translateX(-10%);
	}

	50% {
		transform: translateX(10%);
	}

	100% {
		transform: translateX(-10%);
	}
}

/* Make the element pulse (grow large and small slowly) */
/* Usage
    .myElement {
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 1; 
    }
*/
@-webkit-keyframes pulsate {
	0% {
		-webkit-transform: scale(0.1, 0.1);
		opacity: 0.0;
	}

	50% {
		opacity: 1.0;
	}

	100% {
		-webkit-transform: scale(1.2, 1.2);
		opacity: 0.0;
	}
}

/* Make the element's opacity pulse*/
/* Usage
    .myElement {
        animation: opacityPulse 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0; 
    }
*/
@-webkit-keyframes opacityPulse {
	0% {
		opacity: 0.0;
	}

	50% {
		opacity: 1.0;
	}

	100% {
		opacity: 0.0;
	}
}

/* Make the element's background pulse. I call this alertPulse because it is red. You can call it something more generic. */
/* Usage
    .myElement {
        animation: alertPulse 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 1; 
    }
*/
@-webkit-keyframes alertPulse {
	0% {
		background-color: #9A2727;
		opacity: 1;
	}

	50% {
		opacity: red;
		opacity: 0.75;
	}

	100% {
		opacity: #9A2727;
		opacity: 1;
	}
}


/* Make the element rotate infinitely. */
/* 
Usage
    .myElement {
        animation: rotating 3s linear infinite;
    }
*/
@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes text-flicker {
	0% {
		opacity: 0.1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	2% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	8% {
		opacity: 0.1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	9% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	12% {
		opacity: 0.1;
		text-shadow: 0px 0px rgba(35, 48, 65, 1);
	}

	20% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1)
	}

	25% {
		opacity: 0.3;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1)
	}

	30% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1)
	}

	70% {
		opacity: 0.7;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1)
	}

	72% {
		opacity: 0.2;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1)
	}

	77% {
		opacity: .9;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1)
	}

	100% {
		opacity: .9;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1)
	}
}

@keyframes border-flicker {
	0% {
		opacity: 0.1;
		-webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		-moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
	}

	2% {
		opacity: 1;
		-webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		-moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
	}

	4% {
		opacity: 0.1;
		-webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		-moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
	}

	8% {
		opacity: 1;
		-webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		-moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
	}

	70% {
		opacity: 0.7;
		-webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		-moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
	}

	100% {
		opacity: 1;
		-webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		-moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
		box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
	}
}

@keyframes letter-flicker {
	0% {
		opacity: 0.1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	2% {
		opacity: 0.1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	4% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}


	19% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	21% {
		opacity: 0.1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	23% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	80% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	83% {
		opacity: 0.4;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}

	87% {
		opacity: 1;
		text-shadow: 0px 0px 29px rgba(35, 48, 65, 1);
	}
}

@keyframes hue {
	from {
		-webkit-filter: hue-rotate(0deg);
	}

	to {
		-webkit-filter: hue-rotate(-360deg);
	}
}

@keyframes glowing {
	0% {
		filter: drop-shadow(0 0 -10px $qudos-blue);
		-webkit-filter: drop-shadow(0 0 -10px $qudos-blue);
	}

	40% {
		filter: drop-shadow(0 0 20px $qudos-blue);
		-webkit-filter: drop-shadow(0 0 20px $qudos-blue);
	}

	60% {
		filter: drop-shadow(0 0 20px $qudos-blue);
		-webkit-filter: drop-shadow(0 0 20px $qudos-blue);
	}

	100% {
		filter: drop-shadow(0 0 -10px $qudos-blue);
		-webkit-filter: drop-shadow(0 0 -10px $qudos-blue);
	}
}

@keyframes beforeLineAnim {
	45%,
	50% {
	  background-position: -100% 0;
	}
	45% {
	  transform: scale(1)
	}
	50%,
	95% {
	  transform: scale(1, -1)
	}
	95% {
	  background-position: 80% 0
	}
  }
  
  @keyframes afterLineAnim {
	20%,
	25% {
	  background-position: -100% 0;
	}
	20% {
	  transform: scale(1) rotate(-90deg)
	}
	25% {
	  transform: scale(-1, 1) rotate(-90deg)
	}
	70%,
	75% {
	  background-position: 80% 0;
	}
	70% {
	  transform: scale(-1, 1) rotate(-90deg)
	}
	75% {
	  transform: scale(1, 1) rotate(-90deg)
	}
  }

  @keyframes glitter {
	0% {
	  -webkit-transform: scale(1.0);
	  opacity: 1;
	}
	25% {
	  -webkit-transform: scale(0.5);
	  opacity: 0;
	}
	50% {
	  -webkit-transform: scale(1.0);
	  opacity: 1;
	}
	75% {
	  -webkit-transform: scale(0.5);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: scale(1.0);
	  opacity: 1;
	}
  }