.section-core {
    &--promo {
        padding-top: 6rem;
        display: flex;
        height: 48rem;
        position: relative;

        .card-b {
            transition: all .4s;

            &:hover {
                background-color: $qudos-blue;
                border: 1px solid rgba($qudos-navyblue, .1);
                box-shadow: 0 10px 10px rgba($qudos-blue, .5);

                h2 {
                    color: white;
                }

                p {
                    color: rgba(white, .75);
                }
            }

            &:active {
                box-shadow: 0 2px 3px rgba($qudos-blue, .12), 0 2px 2px rgba($qudos-blue, .24);
            }
        }

        .values__box-square {
            position: unset;
            padding: 1rem;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5rem;
        }

        .col-12 {
            margin-bottom: 3rem;
        }

        .col-sm-12 {
            padding: 2rem;
        }

        h1 {
            max-width: 60%;

            &::after {
                bottom: 0;
            }
        }
    }
}

@media (max-width: 991px) {
    .section-core {
        &--promo {
            h1 {
                max-width: 78%;
            }

            .col-sm-12 {
                padding: .5rem;
            }

        }
    }
}

@media (max-width: 767px) {
    .section-core {
        &--promo {
            height: unset;
            margin-top: 5rem;

            .card-b {
                min-height: 26rem;
                max-width: 22rem;
            }

            .col-sm-12:last-child {
                margin-bottom: 5rem;
            }
        }
    }
}