/*==============================
  Service
==============================*/

.service {
    &__img {
        display: block;
        margin: 0 auto 3rem;
        max-width: 100%;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0 7px 2.9rem 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 2.9rem 6px rgba(0, 0, 0, 0.08);
    }

    &-wrap {
        display: block;
        position: relative;
        background-image: linear-gradient(130deg, #171f2d 0%, #0d111c 34%, #03030a 100%);
        z-index: 1;
    }

    .section__text:last-child {
        margin: 0;
    }
}

@media (min-width: 768px) {
    .service__img {
        margin: 0 auto;
    }
}

@media (min-width: 1200px) {

    .service {
        .section__text,
        .section__title {
            margin-left: 3.2rem;
        }
    }

    .service {
        .section__text:last-child {
            margin: 0 0 0 3.2rem;
        }
    }
}