/*==============================
  Common styles
==============================*/

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  font-size: calc(62.5% + .35vw);
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: white;
  -webkit-font-smoothing: antialiased;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

a {
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  transition: 0.4s ease;
  display: inline-block;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

a:not([href]):not([tabindex]) {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

a:not([href]):not([tabindex]):hover {
  text-decoration: underline;
  color: white;
}

object {
  pointer-events: none;
}


input,
textarea,
select {
  padding: 0;
  margin: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

::-moz-selection {
  background: white;
  color: black;
  text-shadow: none;
}

::selection {
  background: white;
  color: black;
  text-shadow: none;
}

::-webkit-input-placeholder {
  // color: $qudos-placeholder-tint;
  opacity: 1;
}

::-moz-placeholder {
  // color: $qudos-placeholder-tint;
  opacity: 1;
}

:-moz-placeholder {
  // color: $qudos-placeholder-tint;
  opacity: 1;
}

:-ms-input-placeholder {
  // color: $qudos-placeholder-tint;
  opacity: 1;
}